import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x2b/email_1.png"
import email2 from "../assets/blog/ti0x2b/email_2.png"
import email3 from "../assets/blog/ti0x2b/email_3.png"
import page2 from "../assets/blog/ti0x2b/page_2.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat Insights 0x2B: Same old same old

## Banco Montepio: Account Locked Alert
![Email 1](${email1})
- **Rating:** ★★★☆☆
- **Date:** 2024-10-16
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This phishing email is designed to look like an urgent message from Banco Montepio,
claiming that the recipient’s account has been locked and their services suspended.
The email encourages the recipient to click a button to “reactivate” their account
and restore access. However, this button doesn’t lead to a legitimate Banco Montepio
site but instead it takes the user to a fraudulent page designed to steal login credentials.

## IT Department: Messages on Hold
![Email 2](${email2})
- **Rating:** ★★☆☆☆
- **Date:** 2024-10-16
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This one pretends to come from the recipient’s own IT department, warning that there
are important messages on hold due to a technical issue. It urges the recipient
to click a button to rectify the problem and release the messages.
![Page 2](${page2})
This button, however, leads to a credential harvesting page where the attacker collects
usernames and passwords. Emails like this play on trust in your organization, so it’s
always wise to double-check with your IT team directly before clicking any suspicious links.

## Request for Quote: SnakeKeylogger Malware
![Email 3](${email3})
- **Rating:** ★★★☆☆
- **Date:** 2024-10-18
- **Objective:** Malware
- **Analyst:** José Morim

The third email is disguised as a request for a quote, a common message for those
working in sales, procurement, or business operations. The email includes an attachment that
appears to be a legitimate request but actually contains a malicious payload. Once opened,
the attachment installs SnakeKeylogger, a dangerous malware that records everything you type, including passwords
and sensitive information, sending it back to the attacker. Always be cautious of attachments
from unknown sources, especially in unexpected emails.

# Indicators Of Compromise
| TYPE | OBSERVABLE                                                                                                                                                                                                                                                                                                                                                                                                                 |
|------|----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------|
| URL  | hxxps[://]montpio[.]site/                                                                                                                                                                                                                                                                                                                                                                                                  |
| FILE | 534aa893ba0d4042fc480095c71532b2eb036eae96f5527b47b442e613bd6614                                                                                                                                                                                                                                                                                                                                                           |
| FILE | 793deb7f76ef83c9f4e20c9e83925905192c14dfa5ca3b02890178a7566398c2                                                                                                                                                                                                                                                                                                                                                           |
| FILE | c8944db48e686f1793cf3650b7e4edf26701a8f8c608eb330686a0c3f24dcf9b                                                                                                                                                                                                                                                                                                                                                           |
| FILE | a03dbedd695c9782c97e02654f887e01f93813c139f7a20e22ee186127096454                                                                                                                                                                                                                                                                                                                                                           |
| URL  | hxxps[://]userbris[.]top/verifying_email/bapi/composite/v1/private/message/view_bEt=eyJhbGciOiJIUzI1NiJ9[.]eyJjdCI6ImEiLCJiIjoiMTAwNDU1MDAyOCIsInIiOiJodHRwczovL2FwcC5iaW5hbmNlLmNvbS9lbi9teS9zZXR0aW5ncy9wcm9maWxlP19kcD1MM2RsWW5acFpYY3ZkMlZpZG1sbGR6OTBlWEJsUFdSb/FptRjFiSFFtYm1WbFpFeHZaMmx1UFdaaGJITmxKblZ5YkQxaFNGSXdZMGhOTmt4NU9UTmtNMk4xV1cxc2RWbFhOV3BhVXpWcVlqSXdkbHBYTkhaaVdHdDJZekpXTUdSSGJIVmABC/index[.]html |
| URL  | hxxps[://]api[.]telegram[.]org/bot7461961891:AAHpgycZJEK7D2I9irTI6QgjGM_Z4Ne7WIQ/sendDocument                                                                                                                                                                                                                                                                                                                              |
| FILE | 0e3e46351dbec548b63f130f711dbf6e0b3dd887aabeb9ecb8e70a2554a882b3                                                                                                                                                                                                                                                                                                                                                           |
| FILE | 157d92542ca4cf672c51e7722f180b16336562c9e0af0a5b7ebc7ec2a8f1438c                                                                                                                                                                                                                                                                                                                                                           |
| FILE | 603c4177e46aa3e0c1a582b0b0e11932d4dcb4d27baeaa9ef2f6fb32328b433a                                                                                                                                                                                                                                                                                                                                                           |
| FILE | 9439e16739e0c31d19125361cd43706bd1935727137cb86120b7b7f1d2308e7a                                                                                                                                                                                                                                                                                                                                                           |

# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content, and don't worry we won't spam your feed ;)
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle12 = () => {
    useTitle('SucuriLabs | Threat Insights 0x2B');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle12;
