import { useTranslation } from "react-i18next";
import heroImage from '../assets/hero.png';
import badTrainingEmail from '../assets/bad_training.png';
import goodTrainingEmail from '../assets/good_training.png';
import reportingImage from '../assets/reportrate.png';
import microlearningImage from '../assets/microlearning.png';
import { ReactComponent as ThunderbirdIcon } from '../assets/thunderbird.svg';
import { ReactComponent as CpanelIcon } from '../assets/cpanel.svg'
import { ReactComponent as OutlookIcon } from '../assets/outlook.svg'
import { ReactComponent as GmailIcon } from '../assets/gmail.svg'
import { ReactComponent as AzureIcon } from '../assets/azure.svg'
import { ReactComponent as GoogleIcon } from '../assets/google.svg'
import { Buildings, Check, Envelope, X } from "@phosphor-icons/react";
import { useRef, useState } from "react";

const HomePage = () => {
    const [disable, setDisable] = useState(false);
    const formRef = useRef(null);
    const { t } = useTranslation();

    // TODO(morim): Validation and feedback
    const handleSubmit = () => {
        setDisable(true);
        const toast = document.getElementById("toast");
        const rand_id = Math.round(Math.random() * 10000)
        var data = {};
        (new FormData(formRef.current)).forEach(function(v, k) {
            data[k] = v;
        });
        fetch(
            "https://leads.sucurilabs.com/api/v1/beta/",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            }
        ).then((response) => {
            setTimeout(() => {
                document.getElementById(`alert_${rand_id}`).remove()
            }, 3000);
            if (!response.ok) {
                toast.insertAdjacentHTML('beforeend', `<div id="alert_${rand_id}" class="alert alert-error"><span>${t('Could not grab an invite at the moment')}</span></div>`);
                return;
            }
            toast.insertAdjacentHTML('beforeend', `<div id="alert_${rand_id}" class="alert alert-success"><span>${t('Thank you! We are going validate your submission and contact your shortly')}</span></div>`);
        }).catch(() => {
            toast.insertAdjacentHTML('beforeend', `<div id="alert_${rand_id}" class="alert alert-error"><span>${t('Could not grab an invite at the moment')}</span></div>`);
            setTimeout(() => {
                document.getElementById(`alert_${rand_id}`).remove()
            }, 2500);
        }).finally(() => { setDisable(false) });;
    }


    return (<>
        <section>
            <div className="hero min-h-[calc(100vh-68px)]">
                <div className="hero-content flex-col lg:flex-row-reverse">
                    <div className="mockup-window bg-base-300 border border-neutral">
                        <img
                            alt="Human risk management dashboard"
                            src={heroImage}
                            className="w-full rounded-lg"
                        />
                    </div>
                    <div className="max-w-lg">
                        <h1 className="text-5xl font-bold">{t('The people-first way to security')}</h1>
                        <p className="py-6 text-lg">
                            {t('Individualized phishing training, automated security awareness training and advanced behavior change - all in one human risk management platform.')}
                        </p>
                        <button
                            className="btn bg-gradient-to-br from-purple-300/25 via-indigo-500/30 to-teal-300/25 px-12"
                            onClick={() => window.location = "/#join"}
                        >
                            {t('Get Started')}
                        </button>
                    </div>
                </div>
            </div>
        </section>
        <section class="mx-2 lg:mx-auto">
            <div class="flex flex-col mx-auto max-w-screen-xl lg:items-center py-16 lg:pb-16">
                <h1
                    class="text-center text-4xl lg:text-5xl font-semibold"
                >
                    {t('Tired of outdated security training? Tackle real-world threats with unmatched effectiveness')}
                </h1>
                <div className="flex flex-col lg:flex-row justify-center lg:justify-between w-full py-12 gap-8 lg:gap-0">
                    <div className="flex items-center flex-col gap-6">
                        <div className="flex flex-col gap-2">
                            <h4 className="text-lg text-center font-semibold">{t('Traditional platform')}</h4>
                            <img alt="Traditional security content" src={badTrainingEmail} className="rounded-xl" />
                        </div>
                        <div className="flex items-center gap-4 max-w-md flex-wrap">
                            <div className="flex items-center gap-4">
                                <X className="basis-1/12 p-1 text-error" weight="bold" size={32} />
                                <h6 className="basis-11/12 text-lg font-medium">{t('Often fails to address current security threats, making the training less useful.')}</h6>
                            </div>
                            <div className="flex items-center gap-4">
                                <X className="basis-1/12 p-1 text-error" weight="bold" size={32} />
                                <h6 className="basis-11/12 text-lg font-medium">{t('Leaves users unprepared for modern security challenges.')}</h6>
                            </div>
                            <div className="flex items-center gap-4">
                                <X className="basis-1/12 p-1 text-error" weight="bold" size={32} />
                                <h6 className="basis-11/12 text-lg font-medium">{t('Time and money spent on outdated training yield minimal benefits.')}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="hidden mask mask-hexagon lg:flex bg-neutral px-6 items-center text-xl font-bold">VS</div>
                    <div className="lg:hidden divider text-xl font-bold">VS</div>
                    <div className="flex items-center flex-col gap-6">
                        <div className="flex flex-col gap-2">
                            <h4 className="text-xl text-center font-semibold">{t('SucuriLabs platform')}</h4>
                            <img alt="Great security content" src={goodTrainingEmail} className="rounded-xl" />
                        </div>
                        <div className="flex items-center gap-4 max-w-md flex-wrap">
                            <div className="flex items-center gap-4">
                                <Check className="basis-1/12 p-1 text-success font-bold" weight="bold" size={32} />
                                <h6 className="basis-11/12 text-lg font-medium">{t('Focuses on real-world security threats, making the training highly applicable.')}</h6>
                            </div>
                            <div className="flex items-center gap-4">
                                <Check className="basis-1/12 p-1 text-success font-bold" weight="bold" size={32} />
                                <h6 className="basis-11/12 text-lg font-medium">{t('Provides practical skills and knowledge to effectively handle modern security challenges.')}</h6>
                            </div>
                            <div className="flex items-center gap-4">
                                <Check className="basis-1/12 p-1 text-success font-bold" weight="bold" size={32} />
                                <h6 className="basis-11/12 text-lg font-medium">{t('Higher return on investment due to the relevance and applicability of the training.')}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="mx-2 lg:mx-auto">
            <div class="mx-auto min-w-screen-xl max-w-screen-xl lg:items-center space-y-8 lg:py-32 pb-32 lg:pb-16">
                <div class="flex flex-col gap-3 lg:flex-row justify-between">
                    <div class="flex flex-col max-w-lg text-lg gap-2">
                        <h1
                            className="bg-clip-text text-4xl text-left font-bold"
                        >
                            {t('Increase reporting efficiency')}
                        </h1>
                        <p className="prose max-w-md">
                            {t('Empower employees to identify and report advanced phishing attacks. Use SucuriLabs reporting plugins to capture attacks towards your organization.')}
                        </p>
                        <div className="flex items-center gap-4 flex-wrap">
                            <div className="flex w-full items-center gap-4">
                                <Check className="basis-1/12 p-1 text-success font-bold" weight="bold" size={32} />
                                <p className="basis-11/12 prose">{t('Seamless reporting from your inbox')}</p>
                            </div>
                            <div className="flex w-full items-center gap-4">
                                <Check className="basis-1/12 p-1 text-success font-bold" weight="bold" size={32} />
                                <p className="basis-11/12 prose">{t('Phishing report button for faster threat detection')}</p>
                            </div>
                            <div className="flex w-full items-center gap-4">
                                <Check className="basis-1/12 p-1 text-success font-bold" weight="bold" size={32} />
                                <p className="basis-11/12 prose">{t('Automated employee feedback after sucessful reporting')}</p>
                            </div>
                            <div className="flex w-full items-center gap-4">
                                <Check className="basis-1/12 p-1 text-success font-bold" weight="bold" size={32} />
                                <p className="basis-11/12 prose">{t('Integration with key ticketing systems for optimizing SOC workflows')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mockup-window bg-base-300 border border-neutral max-w-screen-sm">
                        <img
                            alt="Increase phishing report rates"
                            src={reportingImage}
                            className="rounded-lg"
                        />
                    </div>
                </div>
            </div>
        </section>
        <section class="mx-2 lg:mx-auto">
            <div class="mx-auto min-w-screen-xl max-w-screen-xl lg:items-center space-y-8 lg:py-32 pb-32 lg:pb-16">
                <div class="flex flex-col-reverse gap-3 lg:flex-row justify-between">
                    <div className="mockup-window bg-base-300 border border-neutral max-w-screen-sm">
                        <img
                            alt="On the stop micro-training moment"
                            src={microlearningImage}
                            className="rounded-lg"
                        />
                    </div>
                    <div class="flex flex-col max-w-lg text-lg gap-2">
                        <h1
                            className="bg-clip-text text-4xl text-left font-bold"
                        >
                            {t('Engaging and relevant learning experiences')}
                        </h1>
                        <p className="prose max-w-md">
                            {t('SucuriLabs automatically delivers on-the-spot micro-trainings to drive engagement and safe behaviors.')}
                        </p>
                        <div className="flex items-center gap-4 flex-wrap">
                            <div className="flex w-full items-center gap-4">
                                <Check className="basis-1/12 p-1 text-success font-bold" weight="bold" size={32} />
                                <p className="basis-11/12 prose">{t('Focuses on quickly closing skill gaps')}</p>
                            </div>
                            <div className="flex w-full items-center gap-4">
                                <Check className="basis-1/12 p-1 text-success font-bold" weight="bold" size={32} />
                                <p className="basis-11/12 prose">{t('Minimizes training time by distributing only necessary lessons')}</p>
                            </div>
                            <div className="flex w-full items-center gap-4">
                                <Check className="basis-1/12 p-1 text-success font-bold" weight="bold" size={32} />
                                <p className="basis-11/12 prose">{t('Story-driven learning experiences that build stronger security habits')}</p>
                            </div>
                            <div className="flex w-full items-center gap-4">
                                <Check className="basis-1/12 p-1 text-success font-bold" weight="bold" size={32} />
                                <p className="basis-11/12 prose">{t('Designed to delight and educate simultaneously, keeping employees alert and prepared for the next cyberattack')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="mx-2 lg:mx-auto">
            <div class="mx-auto min-w-screen-xl max-w-screen-xl lg:items-center space-y-8 lg:py-32 pb-32 lg:pb-16">
                <div class="flex flex-col-reverse gap-3 lg:flex-row md:justify-between">
                    <div class="flex flex-wrap max-w-lg gap-4 w-full lg:basis-1/2 justify-evenly">
                        <div className="flex mask mask-squircle bg-gradient-to-br from-indigo-400/15 via-gray-800/30 to-teal-800/15 p-6 justify-center">
                            <GmailIcon width={64} />
                        </div>
                        <div className="flex mask mask-squircle bg-gradient-to-br from-indigo-400/15 via-gray-800/30 to-teal-800/15 p-6 justify-center">
                            <CpanelIcon width={64} />
                        </div>
                        <div className="flex mask mask-squircle bg-gradient-to-br from-indigo-400/15 via-gray-800/30 to-teal-800/15 p-6 justify-center">
                            <AzureIcon width={64} />
                        </div>
                        <div className="flex mask mask-squircle bg-gradient-to-br from-indigo-400/15 via-gray-800/30 to-teal-800/15 p-6 justify-center">
                            <OutlookIcon width={64} />
                        </div>
                        <div className="flex mask mask-squircle bg-gradient-to-br from-indigo-400/15 via-gray-800/30 to-teal-800/15 p-6 justify-center">
                            <GoogleIcon width={64} height={64} />
                        </div>
                        <div className="flex mask mask-squircle bg-gradient-to-br from-indigo-400/15 via-gray-800/30 to-teal-800/15 p-6 justify-center">
                            <ThunderbirdIcon width={64} />
                        </div>
                    </div>
                    <div class="flex flex-col max-w-lg text-lg gap-2">
                        <h1
                            className="bg-clip-text text-4xl text-left font-bold"
                        >
                            {t('Because your time matters')}
                        </h1>
                        <p className="prose">
                            {t('We integrate with the best in the market to continually enhance our platform and help you tackle the challenges you run into while keeping employees engaged from anywhere.')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="mx-2 lg:mx-auto">
            <div id="join" class="mx-auto min-w-screen-xl max-w-screen-xl lg:items-center space-y-8 lg:py-32 lg:pb-16">
                <div className="flex flex-col">
                    <h1
                        class="bg-clip-text text-4xl text-left font-bold"
                    >
                        {t('Ready for a change? ')}
                    </h1>
                    <h1
                        class="bg-clip-text text-4xl text-left font-bold"
                    >
                        {t('Grab an invite to our private beta!')}
                    </h1>
                </div>
                <form ref={formRef} onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                    <div class="flex lg:flex-row justify-between">
                        <div class="flex flex-col max-w-lg w-full">
                            <label className="form-control w-full max-w-xs">
                                <div className="label pt-0">
                                    <span className="label-text font-semibold">{t('Email')}</span>
                                </div>
                                <div className="input input-bordered flex items-center gap-2">
                                    <Envelope />
                                    <input name="email" type="email" className="grow" placeholder={t('Required')} required />
                                </div>
                                <div className="label pt-0">
                                    <span className="label-text-alt">&nbsp;</span>
                                </div>
                            </label>
                            <label className="form-control w-full max-w-xs">
                                <div className="label pt-0">
                                    <span className="label-text font-semibold">{t('Organization')}</span>
                                </div>
                                <div className="input input-bordered flex items-center gap-2">
                                    <Buildings />
                                    <input name="organization" type="text" className="grow" placeholder={t('Required')} required />
                                </div>
                                <div className="label pt-0">
                                    <span className="label-text-alt">&nbsp;</span>
                                </div>
                            </label>
                            <label className="form-control">
                                <div className="label pt-0">
                                    <span className="label-text font-semibold">{t('How did you hear about us?')}</span>
                                </div>
                                <textarea name="reference" className="textarea textarea-bordered h-24" placeholder={t('Optional')}></textarea>
                                <div className="label pt-0">
                                    <span className="label-text-alt">&nbsp;</span>
                                </div>
                            </label>
                            <button
                                class="btn bg-gradient-to-br from-purple-300/25 via-indigo-500/30 to-teal-300/25  py-3 font-semibold"
                                disabled={disable}

                                type="submit"
                            >
                                <span>{t('Get on the list')}</span>
                            </button>
                        </div>
                        <div class="hidden lg:flex lg:flex-col max-w-lg text-lg gap-3 prose">
                            <p>
                                {t('SucuriLabs Human Risk management platform is a brand new way to manage and reduce risky behaviors on your organization and look great doing it. ')}
                                {t('We\'ll launch to the world this summer.')}
                            </p>
                            <p>
                                {t('Meanwhile, we\'re still working out the kinks. Want to get a peak at what we\'re building? ')}
                                {t('Sign up for the private beta and we\'ll email you with an invite code in the coming weeks.')}
                            </p>
                            <p>{t('No strings attached. For further details, review our ')}<a href="/privacy-policy">{t('Privacy Policy')}</a>.</p>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </>);
}

export default HomePage;
