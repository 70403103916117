import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x28/email_1.png"
import email2 from "../assets/blog/ti0x28/email_2.png"
import email3 from "../assets/blog/ti0x28/email_3.png"
import email4 from "../assets/blog/ti0x28/email_4.png"
import page2 from "../assets/blog/ti0x28/page_2.png"
import page4 from "../assets/blog/ti0x28/page_4.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat Insights 0x28: Guloader Malspam

## Review pending messages
![Email 1](${email1})
- **Rating:** ★★★★☆
- **Date:** 2024-09-27
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This email disguises itself as a notice from the recipient's email service provider,
falsely presenting a "Review pending messages" alert. It claims that certain incoming emails are
being held, suggesting that immediate action is required to access them. The message is
strategically designed to create urgency, enticing the recipient to click a link or button
under the false pretense of resolving the issue and retrieving the pending emails.

## Your inbox is going to be shutdown
![Email 2](${email2})
- **Rating:** ★★☆☆☆
- **Date:** 2024-09-25
- **Objective:** Credential harvesting
- **Analyst:** José Morim

The email falsely claims to be from the recipient's email service provider, urging
them to click a link to prevent their mailbox from being shut down by a certain deadline.
![Page 2](${page2})
However, that link actually takes them to a phishing site intended to harvest their personal information.

## Update payment information!
![Email 3](${email3})
- **Rating:** ★★☆☆☆
- **Date:** 2024-09-24
- **Objective:** Credential harvesting
- **Analyst:** José Morim

The email seems like a straightforward request to refresh payment details for an
Amazon Prime account, but its true purpose is far more sinister. It's crafted to
trick the recipient into clicking a link that directs them to a page designed for stealing credentials.

## Guloader Malspam
![Email 4](${email4})
- **Rating:** ★★★★☆
- **Date:** 2024-09-25
- **Objective:** Malware
- **Analyst:** José Morim

This email masquerades as a payment order, but its intentions are far from innocent.
The real purpose is to mislead the recipient into opening the attached file, which contains a harmful executable.
If the recipient activates the file, it will silently install Guloader on their system,
all while they are under the impression that they are dealing with a valid business request.
![Page 4](${page4})
The attacker’s primary objective is to manipulate the victim into executing the
malicious payload, thereby infecting their system with malware. This allows the
attacker to covertly collect sensitive information and credentials, compromising
the victim’s security.


# Indicators Of Compromise
| TYPE | IOC                                                              |
|------|------------------------------------------------------------------|
| FILE | 783b5b92ea44666e1521eed1d7688f1bdf9044e83ac39258f9905397f52677dd |
| FILE | 376ba06feee16467464fb8a765830c17b65e49f38d07369db1a0eb586fa6ae20 |
| FILE | 187adbae17615b37ae386ebadb4347a41f2a3e994939e832e96f342d91f9d916 |
| FILE | 548928eebb67d6419e329d323637f30d1b1c570a52acc008d339fdab40336c08 |
| FILE | 5fb4c0b14e27e026650eac4da2101b83d70c3a39f8cc008ab5e5c4fcd7837b00 |
| FILE | e4e9cb0519f421b4e7c3ce98cc3593e0f7132d03e77bbf4c9c7ac79f6a0c91ff |
| FILE | 5e945b710d3f774552a57edce050f57a7258d8b1ef5f1114723079b89d10234b |
| FILE | b59c15f1cdcb828e81a8177d96187d90c229369ed512b9aa89cb523e29a0fdcf |
| URL  | hxxps[://]mukulbros[.]com/Webmail/webmail[.]php                  |
| URL  | hxxps[://]verification-portall[.]tiiny[.]site                    |
| URL  | hxxps[://]authenticate-domain[.]latosbono[.]workers[.]dev        |
| URL  | hxxps[://]scureservic-amzisndmsodstetemers[.]line[.]pm/          |

# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content, and don't worry we won't spam your feed ;)
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle10 = () => {
    useTitle('SucuriLabs | Threat Insights 0x28');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle10;
