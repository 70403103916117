import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x22/email_1.png"
import page1 from "../assets/blog/ti0x22/page_1.png"
import email2 from "../assets/blog/ti0x22/email_2.png"
import page2 from "../assets/blog/ti0x22/page_2.png"
import email3 from "../assets/blog/ti0x22/email_3.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat insights 0x22: Agent Tesla
## Fake banking documents
![Email 1](${email1})
- **Rating:** ★★★★☆
- **Date:** 2024-08-17
- **Objective:** Malware - Agent Tesla
- **Analyst:** José Morim

The email disguises itself as an official message from a bank, asking the recipient
to review important documents. However, its true aim is far more dangerous: it tricks
the recipient into opening the attached file, which contains a malicious executable. If
the file is run, it infects the system with the Agent Tesla malware, all while the recipient
believes they're simply handling routine banking documents:
![Page 1](${page1})
The threat actor's main goal is to trick the victim into running the payload, which silently
installs Agent Tesla malware on their system. Once active, the malware covertly gathers sensitive
information and credentials, putting the victim's security at serious risk.


## Your inbox is almost full
![Email 2](${email2})
- **Rating:** ★★☆☆☆
- **Date:** 2024-08-15
- **Objective:** Credential harvesting
- **Analyst:** José Morim


This email cleverly impersonates the recipient's email service provider, urging
them to click a link disguised as information about "storage limits." However,
the link leads to a credential-harvesting page, designed to steal the recipient's
login details.

## Upgrade your inbox
![Email 3](${email3})
- **Rating:** ★★☆☆☆
- **Date:** 2024-08-13
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This email deceptively mimics the recipient's email service provider, pressuring
them to click a link to upgrade their mailbox to avoid potential service interruptions.
But instead of a legitimate upgrade, the link directs them to a credential-harvesting
page designed to steal their credentials:
![Page 3](${page2})

# IOCs
| TYPE | IOC                                                              |
|------|------------------------------------------------------------------|
| URL  | hxxps[://]iaaw[.]wanianten[.]com/                                |
| FILE | c6258e8bfb9bf34b2e1976f35056d2819c2d037ffc44015cd91fb29f16e3dbe3 |
| FILE | 1afeecadee8c36a98747e53bf3a0e9e7a9ffc65d379a5cc9f1ddbce38768d185 |
| FILE | f423d84d2ddc5df53604aee7349c1d505f83c2ff4d40ace8599a3ae250713bde |
| FILE | 174cbb3a7be5fed2b2b3ae4ce39a17b68bec8417203f39ddaf00a97c7a27b17d |
| FILE | c3ce17796ebde43d32873cb874639fa0f715a1c4d9281077c753c792cd9b2349 |
| FILE | ade90b8282cf57d023fd6c350ef1df0a7f2e91cf847c0942de7072f8d5ec3ad5 |
| FILE | f423d84d2ddc5df53604aee7349c1d505f83c2ff4d40ace8599a3ae250713bde |
| FILE | a6fb2f27a0bf0b8aff5bd4d71133256184e16c567b21b5001a0d9bfd730e112a |

# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content, and don't worry we won't spam your feed ;)
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle5 = () => {
    useTitle('SucuriLabs | Threat Insights 0x22');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle5;
