import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x2c/email_1.png"
import email2 from "../assets/blog/ti0x2c/email_2.png"
import email3 from "../assets/blog/ti0x2c/email_3.png"
import page1 from "../assets/blog/ti0x2c/page_1.png"
import page2 from "../assets/blog/ti0x2c/page_2.png"
import page3 from "../assets/blog/ti0x2c/page_3.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat Insights 0x2C: A smugglers tale

Last week phishing campaigns were quite deceptive, with threat actors using HTML
smuggling to harvest user credentials. In this latest wave, attackers disguise HTML
files as common document attachments. These files include realistic forms that
mimic well-known services, prompting users to enter login information. Here’s a
closer look at three phishing emails that use this method to steal credentials.

## Adobe Cloud Storage Impersonation
![Email 1](${email1})
- **Rating:** ★★☆☆☆
- **Date:** 2024-10-25
- **Objective:** Credential harvesting
- **Analyst:** José Morim

The first phishing email pretends to be a message from a payment provider with
an attached “PDF” file labeled as payment details. However, this attachment is
actually an HTML file designed to look like an Adobe Cloud Storage service with a login form.
![Page 1](${page1})
When the recipient opens it, they are asked to enter their credentials to “view” the document.
Once the the user submits the form they are immediately sent to the attacker via Telegram.

## Fake OneDrive Payment Confirmation
![Email 2](${email2})
- **Rating:** ★★☆☆☆
- **Date:** 2024-10-23
- **Objective:** Credential harvesting
- **Analyst:** José Morim

The second email takes the form of a payment confirmation, with an attached file
labeled as a PDF. This attachment, however, is another HTML file, disguised to resemble a
OneDrive login page.
![Page 2](${page2})
Victims are prompted to enter their credentials to access the “payment details.”
Instead of confirming any payment, this action sends the victim’s credentials
straight to the attacker.

## Adobe Acrobat Reader Delivery Notification
![Email 3](${email3})
- **Rating:** ★★★☆☆
- **Date:** 2024-10-22
- **Objective:** Credential harvesting
- **Analyst:** José Morim

The third phishing email claims to be a DHL package delivery notification with an
attachment which is an HTML file. When opened, this file displays a form styled to
look like the Adobe Acrobat Reader interface, prompting the user to log in to view their “package delivery details.”
![Page 3](${page3})
Victims who enter their credentials unwittingly hand over their login information to the attacker.

# Indicators Of Compromise
| TYPE | OBSERVABLE                                                                                   |
|------|----------------------------------------------------------------------------------------------|
| URL  | hxxps[://]api[.]telegram[.]org/bot8071487066:AAFQJ6CIeqCGB61Z_qb5CPIRdwnMQjAqkoc/sendMessage |
| URL  | hxxps[://]nocodeform[.]io/f/671776eb2b0677ec1d04cc64                                         |
| URL  | hxxps[://]stimutompia[.]com/Wyqwimkx/feedback[.]php                                          |
| FILE | 3070afe87f864e601879b58860f9ef3d7d28acbe5d3045985c5325a9197eb4df                             |
| FILE | b419b00888f36655e1d0568a1c24a25954f3bc032d7106511312b277e2c2b735                             |
| FILE | bcb63feef4cc7516b8c497429b51a882d0fc3eba03499187fab06754cab6698c                             |

# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content, and don't worry we won't spam your feed ;)
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle13 = () => {
    useTitle('SucuriLabs | Threat Insights 0x2C');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle13;
