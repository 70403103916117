const FooterLinks = ({ title, links }) => {
    return (
        <div>
            <p class="font-semibold">{title}</p>
            <ul class="mt-2 lg:mt-6 space-y-1 text-sm">
                {
                    links?.map(link => (
                        <li>
                            <a href={link.href} class="transition hover:opacity-75"> {link.label} </a>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};

export default FooterLinks;
