import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x1e/6.png"
import page1 from "../assets/blog/ti0x1e/4.png"
import email2 from "../assets/blog/ti0x1e/1_email.png"
import page2 from "../assets/blog/ti0x1e/3.png"
import email3 from "../assets/blog/ti0x1e/2.png"
import page3 from "../assets/blog/ti0x1e/5.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat insights 0x1E: Credential harvesting
## WeTransfer Impersonation
### Logistics sent you the shipping documents

![Email 1](${email1})
- **Rating:** ★★☆☆☆
- **Date:** 2024-07-18
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This email claims that some shipping documents were sent to the recipient via WeTransfer which is a popular file sharing service. In reality the link leads the victim to a malicious website with the objective of harvesting their credentials:
![Page 1](${page1})
The threat actor claims that the documents will expire on the 24th of July, and this is intended to create a sense of urgency in the recipient to have them click on the various malicious links.

### YOU RECEIVED A FILE FROM A CONTACT
![Email 3](${email3})

- **Rating:** ★★★☆☆
- **Date:** 2024-07-17
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This email impersonates WeTransfer and claims that some files were shared with the recipient. It also states that the files will be deleted on April 29th, which is odd considering today is July 18th. Regardless, this was an attempt to create a sense of urgency to prompt the recipient to open the malicious link.
![Page 3](${page3})
Upon opening the victim is served with a credential harvesting page.


## IT Impersonation
### Server victimdomain[.]com - Password recovery
![Email 2](${email2})
- **Rating:** ★★☆☆☆
- **Date:** 2024-07-17
- **Objective:** Credential harvesting
- **Analyst:** José Morim


This email is claiming to be from the IT administrator that manages the victim
email services. The threat actor says that the victim credentials are expired
and the email server will log them off and create new credentials in the next
24 hours, giving the victim a sense of urgency to click the button "Manter senha atual"
so they can maintain their current credentials:
![Page 2](${page2})
By clicking the button the victim is lured into a malicious website with the objective of stealing the users credentials.

# IOCs

| TYPE   | IOC                                                                     |
|--------|-------------------------------------------------------------------------|
| URL    | hxxps[://]ipfs[.]io/ipfs/QmUa3nCp4R4SqbGBFhGvW6pqoEViwFPtnhaa28EAX9thtv |
| DOMAIN | pub-25902d32074b459eb837a12ad320b79e.r2.dev                             |
| DOMAIN | 0i00045i0.cc                                                            |
| DOMAIN | sudsy-flannel-don.glitch.me                                             |


# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content.
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle = () => {
    useTitle('SucuriLabs | Threat Insights 0x1E');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle;
