import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x2d/email_1.png"
import email2 from "../assets/blog/ti0x2d/email_2.png"
import email3 from "../assets/blog/ti0x2d/email_3.png"
import page1 from "../assets/blog/ti0x2d/page_1.png"
import page2 from "../assets/blog/ti0x2d/page_2.png"
import page3 from "../assets/blog/ti0x2d/page_3.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat Insights 0x2D: Low Hanging Fruit

## WeTransfer impersonation
![Email 1](${email1})
- **Rating:** ★★☆☆☆
- **Date:** 2024-11-01
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This email pretends to be from WeTransfer, claiming the recipient has
received files related to a “New Order Enquiry.” The email is filled with subtle typos,
which may give some users a hunch telling them its fake, but the familiar WeTransfer
logo and layout make it look credible at first glance.
![Page 1](${page1})
If the user clicks the link, they’re directed to a website designed to look like a
WeTransfer download page, complete with a fake login form. Anyone who enters their
credentials here unknowingly sends their login details directly to the attacker.

##  Bogus HR Payroll Schedule on OneDrive
![Email 2](${email2})
- **Rating:** ★★☆☆☆
- **Date:** 2024-10-31
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This email arrives under the guise of an HR payroll schedule for October, supposedly
shared via OneDrive. The email looks official, but when the recipient clicks the link,
they’re taken to a poorly designed webpage claiming to host the payroll document.
![Page 2](${page2})
To access it, users are prompted to “unlock” the document by entering their login information.
Instead of revealing any schedule, this site simply collects the entered credentials,
compromising the victim’s security.

## MetaMask KYC Verification
![Email 3](${email3})
- **Rating:** ★★☆☆☆
- **Date:** 2024-10-30
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This phishing email takes advantage of recent KYC (Know Your Customer) policies
for cryptocurrency platforms. It poses as a MetaMask notification, warning that
the recipient’s account will be suspended within 48 hours if verification isn’t completed.
The sense of urgency in the email often prompts quick action, leading victims to a fake MetaMask page.
![Page 3](${page3})
Here, they’re asked to enter their recovery phrases, which are then immediately
captured by the attackers, giving them full access to the wallet and any assets within.

# Indicators Of Compromise
| TYPE | OBSERVABLE                                                                                      |
|------|-------------------------------------------------------------------------------------------------|
| URL  | hxxps[://]ipfs[.]io/ipfs/QmQMPh4PpbCGJEVTmjv47d21ApFSHSZnK4pEL46womAJC5/update-we%20image[.]htm |
| URL  | hxxps[://]ipfs[.]io/ipfs/QmbgJuiJ5Hb8FEeVgZxmzSymwb8T2zVCCJWyB61kCfxswC/octoberdoc[.]html       |
| URL  | hxxps[://]seomni[.]com/mt                                                                       |
| URL  | hxxps[://]smartcaptchapro[.]com/                                                                |
| URL  | hxxps[://]smartcaptchapro[.]com/import/home                                                     |

# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content, and don't worry we won't spam your feed ;)
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle14 = () => {
    useTitle('SucuriLabs | Threat Insights 0x2D');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle14;
