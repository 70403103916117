import ReactMarkdown from "react-markdown";

const md = `
Our Story
---------

At SucuriLabs, we want to help all the types businesses grow safely, and be resilient
by simplifying "the security stuff" for them, and we believe that this will make
the world a better and safer place.

What if security was an opportunity and not an obstacle? What if you could securely
advance your business with clarity and confidence?

Your security is not merely a hypothetical scenario. It should be a reality for
organizations of all shapes and sizes. That's why we're commited to helping you build
security into the heart of your business. We're here to make security and growth
easier for your teams so they can grow as big as their dreams.

**That's why we do what we do, that's why we got your back.**

Our people
----------

We're a security passionate team of software, operations, security engineers,
and everything in between.

We are defenders. It is why we exist. Born from hustle, we've spent countless
hours sharpening ourselves to make things better for our customers. Cybersecurity
is constantly changing. Today's challenges are nothing like tomorrow's.
Some wait and react. At SucuriLabs, we innovate. Our mission is to be the change
we want to see in the world and create a more secure future.

**That's why we are what we are, that's why we got your back.**
`;

const AboutUs = () => {
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl lg:flex lg:h-screen lg:items-center">
                    <div class="mx-auto text-center">
                        <h1
                            class="bg-clip-text text-5xl font-bold "
                        >
                            You Protect Our Future. We've Got Your Back.
                        </h1>
                    </div>
                </div>
            </section>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown>{md}</ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUs;
