import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x27/email_1.png"
import email2 from "../assets/blog/ti0x27/email_2.png"
import email3 from "../assets/blog/ti0x27/email_3.png"
import page1 from "../assets/blog/ti0x27/page_1.png"
import page2 from "../assets/blog/ti0x27/page_2.png"
import page3 from "../assets/blog/ti0x27/page_3.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat insights 0x27: A smugglers tale

## Smuggling credentials out
![Email 1](${email1})

- **Rating:** ★★★☆☆
- **Date:** 2024-09-18
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This phishing email aims to masquerade as a sales contract that a client has supposedly
requested. It pressures the recipient to make necessary corrections and verify all
details prior to processing the payment, creating a sense of urgency to entice
the recipient into opening the attached files.
![Page 1](${page1})
When opened, the HTML attachment builds a "Adobe Cloud Storage" credential harvesting form hosted
on the recipient’s local machine. Entering a password and clicking “View” will
send the login credentials to a third-party server controlled by bad actors.


## Password expiration
![Email 2](${email2})
- **Rating:** ★★☆☆☆
- **Date:** 2024-09-19
- **Objective:** Credential harvesting
- **Analyst:** José Morim


This email deceptively claims to originate from the recipient's email service provider,
urging them to click a link to prevent their mailbox from being deactivated within
48 hours due to an expired password.
![Page 2](${page2})
However, the link actually directs them to a phishing site aimed at stealing their credentials.

## DocuSign impersonation
![Email 3](${email3})

- **Rating:** ★★★☆☆
- **Date:** 2024-09-21
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This deceptive email is crafted to look like it’s from DocuSign, suggesting that
the recipient has a document that is ready for their examination.
![Page 3](${page3})
However, clicking the "Preview Document" button leads them to a malicious site designed to harvest their login information.

# IOCs
| TYPE | IOC                                                                                  |
|------|--------------------------------------------------------------------------------------|
| FILE | 15c45b0f142cb6cf415aeed88c8b74c0dfe796a6e9ac5da2528f46c77d4dc9ad                     |
| URL  | hxxps[://]online[.]advancements[.]best/communication[.]aspx                          |
| URL  | hxxps[://]ipfs[.]io/ipfs/bafkreih3s4d2n2b74vd6zmfvebu2w3rxvb4x7r7awqxte55sf47kzt446m |
| URL  | hxxps[://]a[.]edic[.]blog/wbb/pdfz[.]php                                             |

# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content, and don't worry we won't spam your feed ;)
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle9 = () => {
    useTitle('SucuriLabs | Threat Insights 0x27');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle9;
