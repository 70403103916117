import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x24/email_1.png"
import email2 from "../assets/blog/ti0x24/email_2.png"
import email3 from "../assets/blog/ti0x24/email_3.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat insights 0x24: Phishing for credentials
## Activate 2FA

![Email 1](${email1})

- **Rating:** ★★☆☆☆
- **Date:** 2024-08-27
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This email aims to impersonate the recipient's email service provider, creating
a false sense of urgency by claiming that the account will be unable to send or
receive emails unless verified within 24 hours. The call to action is a button
labeled to activate two-factor authentication (2FA), misleading the user into
believing that this step is necessary to secure their account and maintain email
functionality.

## We are upgrading our webmail software
![Email 2](${email2})
- **Rating:** ★★☆☆☆
- **Date:** 2024-08-28
- **Objective:** Credential harvesting
- **Analyst:** José Morim


This email falsely appears to be from the recipient's email service provider, pressuring
them to click a link to upgrade their mailbox to avoid potential service interruptions.
However, the link leads to a credential-harvesting page designed to steal their login
information instead of providing a legitimate upgrade.

## You have incoming messages on hold
![Email 3](${email3})
- **Rating:** ★★☆☆☆
- **Date:** 2024-08-30
- **Objective:** Credential harvesting
- **Analyst:** José Morim


This email masquerades as a notice from the recipient's email service provider,
deceptively presenting itself as a "Pending Mails" alert. It claims that two
incoming messages are currently on hold, implying that the recipient must take
immediate action to release and receive these emails. The message is designed
to create a sense of urgency, encouraging the recipient to click a link or button
under the pretense of resolving the issue and accessing the delayed emails.

# IOCs
| TYPE | IOC                                                                                                       |
|------|-----------------------------------------------------------------------------------------------------------|
| URL  | hxxps[://]email[.]poppular[.]click/maintainance[.]aspx                                                    |
| URL  | hxxp[://]mortgageboss[.]ca/link[.]aspx                                                                    |
| URL  | hxxps[://]servidorportal[.]com/gmsabav6u2ni24ttnvmnlrxgnqbafkreiet7olwidaqbs7ankqng2zcc2o4/               |
| URL  | hxxps[://]bafybeih227agijgldkxteu4vrfimz4lnbba7prgcz3jxwmsty4iujerjk4[.]ipfs[.]dweb[.]link/Dekan111[.]htm |

# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content, and don't worry we won't spam your feed ;)
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle6 = () => {
    useTitle('SucuriLabs | Threat Insights 0x24');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle6;
