import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x26/email_1.png"
import email2 from "../assets/blog/ti0x26/email_2.png"
import email3 from "../assets/blog/ti0x26/email_3.png"
import email4 from "../assets/blog/ti0x26/email_4.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat insights 0x26: Harvesting season
## Docusign impersonation

![Email 1](${email1})

- **Rating:** ★★★☆☆
- **Date:** 2024-09-10
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This phishing email is designed to impersonate DocuSign, claiming that the recipient
has a completed document ready for review. However, when the recipient clicks
the "Preview Document" button, they are redirected to a credential-harvesting site.

## Undelivered mail!
![Email 2](${email2})
- **Rating:** ★★☆☆☆
- **Date:** 2024-09-10
- **Objective:** Credential harvesting
- **Analyst:** José Morim


This email falsely appears to be from the recipient's email service provider, pressuring
them to click a link retrieve undelivered mail.
However, the link leads to a credential-harvesting page designed to steal their login
information instead of providing the legitimate messages.

## We've closed your account
![Email 3](${email3})
- **Rating:** ★★★☆☆
- **Date:** 2024-09-11
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This phishing email is impersonating Wise, falsely claiming that the recipient's account
has been closed. It invites the recipient to submit an appeal to reverse the account closure,
but this call to action directs them to a credential-harvesting site.

## Metamask impersonation
![Email 4](${email4})
- **Rating:** ★★★☆☆
- **Date:** 2024-09-10
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This phishing email is impersonating MetaMask, falsely claiming there has been a
login to the account from an unknown location. It urges the recipient to click a button
to recover their account, but this link redirects to a credential-harvesting site.

# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content, and don't worry we won't spam your feed ;)
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle6 = () => {
    useTitle('SucuriLabs | Threat Insights 0x24');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle6;
