import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x2e/email_1.png"
import email2 from "../assets/blog/ti0x2e/email_2.png"
import email3 from "../assets/blog/ti0x2e/email_3.png"
import email4 from "../assets/blog/ti0x2e/email_4.png"
import page1 from "../assets/blog/ti0x2e/page_1.png"
import page2 from "../assets/blog/ti0x2e/page_2.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";
import { useTranslation } from "react-i18next";

const md = `
# Threat Insights 0x2E: Smuggling packages
Last week saw a wave of new phishing emails designed to steal user credentials, exploiting themes of file sharing and package delivery. One lure involved a fake OneDrive notification, while the rest took advantage of the Black Friday shopping rush, impersonating DHL to trick users into handing over their login details. Let’s dive into the details of these phishing emails so you know what to look out for.

## OneDrive file share impersonation
![Email 1](${email1})
- **Rating:** ★★★☆☆
- **Date:** 2024-11-09
- **Objective:** Credential harvesting
- **Analyst:** José Morim

One of the phishing emails disguises itself as a OneDrive file-sharing notification,
informing recipients that a new file has been shared with them.
The link in the email leads to a malicious website that mimics Microsoft’s clean login page.
![Page 1](${page1})
While it looks official, this fake login page is designed to capture any credentials entered, immediately sending them to the attacker.

## DHL Black Friday Delivery Notifications: HTML Smuggling Attack
![Email 2](${email2})
![Email 3](${email3})
- **Rating:** ★★☆☆☆
- **Date:** 2024-11-05
- **Objective:** Credential harvesting
- **Analyst:** José Morim

The rest of the phishing emails we found last week all follow a common theme: DHL
package delivery notifications, playing into the Black Friday shopping frenzy.
We believe it was sent from a single threat actor, each of these emails contains an
HTML attachment that seems like an innocent tracking file, but it’s actually designed to phish credentials using HTML smuggling.
![Email 4](${email4})
When opened, the HTML file displays a login form styled in DHL’s colors, asking users to enter their credentials to access tracking information. Once the user submits the form, their details are instantly exfiltrated to the attacker. With holiday shopping season in full swing, these kinds of package delivery scams are especially effective, as many people are expecting deliveries from recent purchases.
![Page 2](${page2})

# Act Now to Defend Against Phishing Threats with SucuriLabs’s AI-Driven Solution
SucuriLabs **CyberHook** uses artificial intelligence to tailor phishing simulations and awareness
training to each employee’s learning style, empowering your team to recognize threats before they happen.
Take the proactive approach to cybersecurity with a platform that adapts to your team’s needs and makes
security everyone’s priority. Get started today and cancel anytime!
`;

const afterCta = `
# Indicators Of Compromise
| TYPE | OBSERVABLE                                                       |
|------|------------------------------------------------------------------|
| URL  | hxxps[://]agerenee[.]com/ptr/app/                                |
| FILE | 83fc610f57e3d75f766c82d050abe79b30250fb8be8551837bc11849f6014a71 |
| FILE | 6b2544c3f872d4bc7e0c262fa0104c0b68784edf1b854c664bd2a0498dd066d2 |
| FILE | 83fc610f57e3d75f766c82d050abe79b30250fb8be8551837bc11849f6014a71 |
| FILE | c89e28b4c663b33387aca56bc3b0b546a4dc6fefe66da6e1844e19f482770b31 |

# Keep up with threat insights
Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!
`

const BlogArticle15 = () => {
    const { t } = useTranslation();
    useTitle('SucuriLabs | Threat Insights 0x2E');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown>
                            {md}
                        </ReactMarkdown>
                        <button
                            class="btn bg-gradient-to-br from-purple-300/25 via-indigo-500/30 to-teal-300/25 px-12"
                            onClick={() => window.location = "/#join"}
                        >
                            {t('Get Started')}
                        </button>
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {afterCta}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle15;
