import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x20/email_1.png"
import page1 from "../assets/blog/ti0x20/page_1.png"
import email2 from "../assets/blog/ti0x20/email_2.png"
import page2 from "../assets/blog/ti0x20/page_2.png"
import email3 from "../assets/blog/ti0x20/email_3.png"
import page3 from "../assets/blog/ti0x20/page_3.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat insights 0x20: Infected
## Fake shipping documents

![Email 1](${email1})

- **Rating:** ★★★★☆
- **Date:** 2024-07-31
- **Objective:** Malware - Remcos RAT
- **Analyst:** José Morim

This email claims that the user has a couple due shipping documents to check. The goal is to get the
recipient to open the malicious archive attached to the email and run a javascript file:
![Page 1](${page1})

The malicious script runs a 2nd stage powershell script that drops the malware, infects and achieves persistance on the
victim machine.


## Account suspension update
![Email 2](${email2})
- **Rating:** ★★☆☆☆
- **Date:** 2024-07-30
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This email is claiming to be from the recipients email service provider. It falsely
claims impending account suspension and urges the recipient to login to their email
by clicking on the "Validate receipient@email.address Account" button:
![Page 2](${page2})
By clicking the button the victim is lured into a malicious website impersonating a cPanel webmail service
with the objective of stealing their credentials.

## New payroll update
![Email 3](${email3})
- **Rating:** ★★☆☆☆
- **Date:** 2024-07-30
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This email impersonates the accounting department and claim to be a payroll update.
The receipient is triggered to open a malicious link to check their new wage which should
be payed by the end of august.
![Page 3](${page3})
After they click the link, the user is served a generic credential harvesting page.

# IOCs
| TYPE | IOC                                                                                    |
|------|----------------------------------------------------------------------------------------|
| FILE | 9d8821e8462c19565876d64aa8dfce3c7ab13138a4c949fad1adeb2837c6e7ad                       |
| FILE | e3bb14f9a0e4cde6b4df21b5a592b9974b1d8613f0ddf044f51b05c6368a3cd3                       |
| URL  | hxxps[://]pastecode[.]dev/raw/za6ml0dw/paste1[.]txt                                    |
| URL  | hxxps[://]ia601606[.]us[.]archive[.]org/10/items/deathnote_202407/deathnote[.]jpg      |
| URL  | hxxps[://]ipfs[.]io/ipfs/bafybeig2xqub5ymsaz7v2hxg4qyhk4njrki4zofy5qcm33nsmlk3o5x2hu   |
| URL  | hxxps[://]ipfs[.]io/ipfs/Qme6GwzDgftG6PoS19DCCKapAxZwJ4bjZQwgpuMZkC1sMC/webcube[.]html |

# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content, and don't worry we won't spam your feed ;)
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle3 = () => {
    useTitle('SucuriLabs | Threat Insights 0x20');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle3;
