import { ReactComponent as Logo } from "../../assets/oglogo.svg"
import { useTranslation } from "react-i18next"

const Header = () => {
    const { t } = useTranslation();
    return (
        <div className="navbar bg-base-100 sticky top-0 z-50 lg:justify-center">
            <div className="flex w-full lg:max-w-screen-xl">
                <div className="navbar-start">
                    <div className="dropdown">
                        { /* remove xs:hidden to create the menu */}
                        <div tabIndex={0} role="button" className="btn btn-ghost xs:hidden lg:hidden">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h8m-8 6h16" />
                            </svg>
                        </div>
                        <ul
                            tabIndex={0}
                            className="menu menu-sm dropdown-content bg-neutral rounded-box z-[1] mt-3 w-52 p-2 shadow">
                            <li><a href="/">{t('Home')}</a></li>
                            <li><a href="/blog/">{t('Blog')}</a></li>
                            <li><a href="/about">{t('About us')}</a></li>
                            <li>
                                <p>{t('Legal')}</p>
                                <ul className="p-2">
                                    <li><a href="/privacy-policy">{t('Privacy policy')}</a></li>
                                    <li><a href="/cookie-policy">{t('Cookie policy')}</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <a className="btn text-xl hidden lg:inline-flex justify-start" href="/">
                        <Logo className="h-5 lg:h-6" />
                    </a>
                </div>
                <div className="navbar-center">
                    <a className="btn text-xl lg:hidden" href="/">
                        <Logo className="h-5 lg:h-6" />
                    </a>
                    { /* add lg:flex after hidden to display */ }
                    <ul className="hidden lg:flex menu menu-horizontal px-1">
                        <li><a href="/blog/">{t('Blog')}</a></li>
                        <li><a href="/about">{t('About us')}</a></li>
                        <li>
                            <div className="dropdown dropdown-bottom dropdown-hover">
                                <div tabIndex={0} role="button">{t('Legal')}</div>
                                <ul
                                    tabIndex={0}
                                    className="menu dropdown-content bg-neutral rounded-box z-[1] mt-1 w-52 p-2 shadow ms-0"
                                >
                                    <li ><a href="/privacy-policy">{t('Privacy policy')}</a></li>
                                    <li><a href="/cookie-policy">{t('Cookie policy')}</a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="navbar-end">
                    &nbsp;
                </div>
            </div>
        </div>
    )
}

export default Header
