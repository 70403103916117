import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x29/email_1.png"
import email2 from "../assets/blog/ti0x29/email_2.png"
import email3 from "../assets/blog/ti0x29/email_3.png"
import page1 from "../assets/blog/ti0x29/page_1.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat Insights 0x29: Infostealers Bonanza

## Multiple infostealer campaign

A new phishing campaign has been discovered, using cleverly disguised emails to
deliver three different types of infostealer malware. These emails, which appear to
be part of everyday business communication, trick recipients into downloading and
running malicious files. Once executed, these files quietly steal sensitive information
from the victim's system. Here’s a closer look at how each email operates and the malware it delivers.

### AgentTesla: Disguised as a Payment Receipt
![Email 1](${email1})
- **Rating:** ★★★★☆
- **Date:** 2024-09-30
- **Objective:** Malware
- **Analyst:** José Morim

The first phishing email is presented as a payment receipt, with a subject line and
content indicating that a payment has been made. Attached is a file named “Payment-Advice.bat”,
which appears to be a harmless receipt document. However, this is actually a malicious
executable designed to deliver AgentTesla, a well-known infostealer malware.
Once run, AgentTesla silently captures keystrokes, screenshots, and login credentials,
sending them back to the attacker—all while the victim believes they are simply reviewing payment details.

### FormBook: Masked as Shipping Documents
![Email 2](${email2})
- **Rating:** ★★☆☆☆
- **Date:** 2024-10-01
- **Objective:** Malware
- **Analyst:** José Morim

The second phishing attempt comes in the form of a fake shipping notification.
The email claims that important shipping documents are attached and provides a
file named “Shipping Documents”, which is an archive containing a malicious executable.
When opened, this executable installs FormBook, another infostealer that targets web
forms and browsers, collecting sensitive data such as credit card details, login
information, and more. Victims think they are tracking a shipment, but in reality,
they are unknowingly handing over their personal and financial information.

### SnakeKeylogger: A Sophisticated Phishing Email from a Compromised Account
![Email 3](${email3})
- **Rating:** ★★★★★
- **Date:** 2024-10-01
- **Objective:** Malware
- **Analyst:** José Morim

The third email is particularly convincing, as it originates from a legitimate,
compromised corporate account. Well-written and professional, the email includes an
attachment labeled “NOSSA CONSULTA REF...TA.pdf.r00”, which seems to be a PDF file
containing a pricing table. However, the file is an archive hiding a malicious executable.
Once the attachment is opened, SnakeKeylogger is deployed. This malware logs
keystrokes and collects sensitive information, giving the attacker access to everything
the victim types, including passwords and confidential business details.

![Page 1](${page1})
This campaign highlights how attackers use familiar scenarios—payment receipts,
shipping documents, and legitimate business correspondence—to trick victims into downloading malware.
To protect yourself, always be cautious when receiving unsolicited emails, especially those with attachments.
Double-check the sender’s email address, verify the legitimacy of the message, and
avoid opening files or clicking links from unknown or unexpected sources. By staying vigilant
and aware of these tactics, you can prevent these infostealers from compromising your
system and stealing your sensitive data.

# Indicators Of Compromise
| TYPE | OBSERVABLE                                                       |
|------|------------------------------------------------------------------|
| FILE | 04f182a2ad9acd3d6fd430e5a8bff1479832ac21832b2e1910f2f4c38fca684a |
| FILE | a8c4f0571c22d1dcbe81839bef807d8f396c18a4e29953bd3c390cd50dc58ef7 |
| FILE | d2174ddc2b6341b898b939fa0661d2a1cd8d485f5237427331f0d889672fbe7e |
| FILE | 3aede15bd004fc6547c3dbe0936b00c4a5fc52906dc1851bc54fcf454d1bca0d |
| FILE | 51d676adb5e2778658530b16839f068d4ed5b15b80bc2056805f455cb2f2949d |
| FILE | f56034ae5ae9fa0ecaf731b60996f361f92ce5e386b6d5bff923eb18368f0c9d |
| FILE | 153bb6af09e5d110b2a8defcfca2ae2f251a999693ae6e8931ff97eb3e9daae2 |
| FILE | b4c18308278e15fffc3d0ead0f3360003906d151b26eefc198214669a985f6b6 |
| FILE | d2174ddc2b6341b898b939fa0661d2a1cd8d485f5237427331f0d889672fbe7e |
| FILE | 02abb1d01386d7d7ffd8debc2c0fb09baebb82d88b8f758e4de3f0deaaecfbf9 |
| FILE | be7e0612f725604fe15474f21f6eb8a2a574110b745ad7b8062b583807bd870a |
| FILE | fc45bc5328ca936c194bea939abea09aaed4394bd249f16c5bde729769e71ed3 |
| FILE | d2174ddc2b6341b898b939fa0661d2a1cd8d485f5237427331f0d889672fbe7e |

# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content, and don't worry we won't spam your feed ;)
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle11 = () => {
    useTitle('SucuriLabs | Threat Insights 0x29');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle11;
