import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x1f/email_1.png"
import page1 from "../assets/blog/ti0x1f/page_1.png"
import email2 from "../assets/blog/ti0x1f/email_2.png"
import page2 from "../assets/blog/ti0x1f/page_2.png"
import email3 from "../assets/blog/ti0x1f/email_3.png"
import page3 from "../assets/blog/ti0x1f/page_3.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat insights 0x1F: Clever tactics
## Your invoice has been generated

![Email 1](${email1})

- **Rating:** ★★★☆☆
- **Date:** 2024-07-22
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This email claims that the user has a due invoice. The goal is to get the
recipient, unaware of what service does the invoice belong, to open a PDF
document attached to the email:
![Page 1](${page1})

The malicious actors trick the recipient to click on a link embedded in the PDF
which sends the victim to a credential harvesting website.


## Unusual activity, action required
![Email 2](${email2})
- **Rating:** ★★☆☆☆
- **Date:** 2024-07-23
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This email is claiming to be from the recipient email service provider. It falsely
claims impending account suspension due to suspicious activity and urges the
recipient to login to their email by clicking on the "Verify your identity" button:
![Page 2](${page2})
By clicking the button the victim is lured into a malicious website with the objective of stealing
their credentials.

## Document awaits your review and signature
![Email 3](${email3})
- **Rating:** ★★★☆☆
- **Date:** 2024-07-26
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This email pretends to be a shared document notification informing the recipient
about a shared file. There is no file name but instead the recipient is informed
that is a secure and encrypted file, in order to arouse curiosity and trick the
user into clicking the "View Your Message" button.
![Page 3](${page3})
After they click the button, the user is served a credential harvesting page.

# IOCs
| TYPE   | IOC                                                              |
|--------|------------------------------------------------------------------|
| FILE   | fbfe3db50d4ae40307b66007715f3507f929dc4b7ea65583366313bb79d79f29 |
| URL    | hxxps[://]g3o9[.]short[.]gy/ct7TBn                               |
| DOMAIN | pub-b148b1b4b9304dbd80461a366cda853c[.]r2[.]dev                  |
| URL    | hxxps[://]t[.]co/R6uQ3sOuOq                                      |
| URL    | hxxps[://]sl[.]tij1111[.]za[.]com/[.]ex/index[.]html             |

# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content, and don't worry we won't spam your feed ;)
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle2 = () => {
    useTitle('SucuriLabs | Threat Insights 0x1F');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle2;
