import ReactMarkdown from "react-markdown";

const md = `
Cookie Policy
=============

We use cookies to help improve your experience of [https://sucurilabs.com](https://sucurilabs.com/). This cookie policy is part of SucuriLabs’s privacy policy, and covers the use of cookies between your device and our website. We also provide basic information on third-party services we may use, who may also use cookies as part of their service, though they are not covered by our policy. If you don’t wish to accept cookies from us, you should instruct your browser to refuse cookies from [https://sucurilabs.com](https://sucurilabs.com/) with the understanding that we may be unable to provide you with some of your desired content and services.

#### What are cookies?

Cookies are small pieces of data that a website asks your browser to store on your computer or mobile device when you visit the website or certain pages. The cookie allows the website to “remember” your actions or preferences over time. Placing cookies will help the website recognize your device for the next time you visit it. We use the term cookies in this policy to refer to all files that collect information in this way and the term website to refer to the SucuriLabs website.

Most browsers support cookies, but users can set their browsers to decline them and can delete them whenever they like. Cookies can contain the name of the website where the cookie has come from, how long the cookie will remain on your device, and a value which is usually a randomly generated unique number.

Cookies set by the website you are visiting are normally referred to as “first-party cookies”, and typically only track your activity on that particular website. Cookies set by external websites and companies are called “third-party cookies”, and can be used to track you on other websites that use the same third-party service.

Some cookies will be deleted as soon as you leave the website (the so-called “session cookies”), other cookies will remain stored on your computer or mobile device and will help us identify you as a visitor of our website (the so-called “permanent cookies”).

Refusing to use cookies on the website may result in the inability to access some of your areas or to receive personalized information.

#### What are cookies for?

Cookies on our website are used for a variety of different purposes. We use cookies to improve the user-experience on our website and to map your surfing behaviour (e.g. the pages you have visited and the time you spent on that page).

Cookies are used to enable certain features (eg. logging in), to track website usage (eg. analytics), to store your user settings (eg. timezone, notification preferences), and to personalise your content (eg. advertising, language).

Cookies make our website easier to use and allow us to better tailor our website to your interests and needs. Cookies are also used to help speed up your future activities and experience on our website. We also use cookies to compile anonymous, aggregated statistics that allow us to understand how our website is being used and how we can improve our services.

**Cookie types:**

*   Essential cookies

Essential cookies are crucial to your experience of a website, enabling core features like user logins, account management, shopping carts and payment processing. We use essential cookies to enable certain functions on our website.

*   Performance cookies

Performance cookies are used in the tracking of how you use a website during your visit, without collecting personal information about you. Typically, this information is anonymous and aggregated with information tracked across all website users, to help companies understand visitor usage patterns, identify and diagnose problems or errors their users may encounter, and make better strategic decisions in improving their audience’s overall website experience. These cookies may be set by the website you’re visiting (first-party) or by third-party services. We use performance cookies on our website.

*   Functionality cookies

Functionality cookies are used in collecting information about your device and any settings you may configure on the website you’re visiting (like language and time zone settings). With this information, websites can provide you with customised, enhanced or optimised content and services. These cookies may be set by the website you’re visiting (first-party) or by third-party service. We use functionality cookies for selected features on our website.

*   Targeting/advertisement cookies

Targeting/advertising cookies are used in determining what promotional content is more relevant and appropriate to you and your interests. Websites may use them to deliver targeted advertising or to limit the number of times you see an advertisement. This helps companies improve the effectiveness of their campaigns and the quality of content presented to you. These cookies may be set by the website you’re visiting (first-party) or by third-party services. Targeting/advertising cookies set by third-parties may be used to track you on other websites that use the same third-party service. We use targeting/advertising cookies on our website.

*   Third-party cookies

We may employ third-party companies and individuals on our websites—for example, analytics providers and content partners. We grant these third parties access to selected information to perform specific tasks on our behalf. They may also set third-party cookies in order to deliver the services they are providing. Third-party cookies can be used to track you on other websites that use the same third-party service. As we have no control over third-party cookies, they are not covered by SucuriLabs’s cookie policy.

#### Our third-party privacy promise

We review the privacy policies of all our third-party providers before enlisting their services to ensure their practices align with ours. We will never knowingly include third-party services that compromise or violate the privacy of our users.

#### How to manage or delete cookies?

You can at any time manage or delete cookies using the settings on your internet browser, allowing you to refuse some or all cookies.

Most browsers are configured to accept cookies by default, but you can update these settings to either refuse cookies altogether, or to notify you when a website is trying to set or update a cookie. If you browse websites from multiple devices, you may need to update your settings on each individual device.

Although some cookies can be blocked with little impact on your experience of a website, blocking all cookies may mean you are unable to access certain features and content across the websites you visit. Turning off cookies will limit the service that we are able to provide and may affect your user-experience. Deleting cookies may result in manually adjusting preferences every time you visit our Website.

For more details about controlling and/or deleting cookies, visit the page corresponding to your browser for more information:

[Safari](https://support.apple.com/)

[Google Chrome](https://support.google.com/chrome/answer/95647)

[Mozilla Firefox](https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer)

[Internet Explorer](https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies)

For other browsers, please consult the documentation provided by the browser operator.
`;

const CookiePolicyPage = () => {
    return (
        <section class="bg-gray-950 text-gray-50">
            <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                <div class="lg:mx-auto max-w-screen-lg text-left prose">
                    <ReactMarkdown>{md}</ReactMarkdown>
                </div>
            </div>
        </section>
    );
}

export default CookiePolicyPage;
