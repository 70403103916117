import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x25/email_1.png"
import page1 from "../assets/blog/ti0x25/page_1.png"
import email2 from "../assets/blog/ti0x25/email_2.png"
import email3 from "../assets/blog/ti0x25/email_3.png"
import page3 from "../assets/blog/ti0x25/page_3.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat Insights 0x25: AdwinRAT disguesed as invoice
## AdwinRAT delivered through email attachments

![Email 1](${email1})

- **Rating:** ★★★★☆
- **Date:** 2024-09-05
- **Objective:** Malware
- **Analyst:** José Morim

The email is disguised as an invoice but has a far more malicious intent. Its true
purpose is to deceive the recipient into opening the attached file, which contains
a harmful executable. If the recipient runs the file, it installs AdwinRAT on their
system, all while they believe they are managing a legitimate business request:
![Page 1](${page1})
The attacker’s primary objective is to manipulate the victim into executing the
malicious payload, thereby infecting their system with malware. This allows the
attacker to covertly collect sensitive information and credentials, compromising
the victim’s security.

## Portugal tax authority impersonation
![Email 2](${email2})
- **Rating:** ★★★☆☆
- **Date:** 2024-09-06
- **Objective:** Personal identification harvesting
- **Analyst:** José Morim

In this phishing email, the attacker tries to trigger a sense of panic in the recipient
by impersonating the local tax authority, pressuring the recipient click a link to see
their tax recalculation request. However, this link is deceptive and leads to a malicious
website designed to harvest personal information.

## Payment refunded!
![Email 3](${email3})
- **Rating:** ★★★☆☆
- **Date:** 2024-09-03
- **Objective:** Malware
- **Analyst:** José Morim


The email appears to be a payment refund notification, but its actual intent is
much more malicious. It is designed to deceive the recipient into downloading and
executing a file that contains harmful malware:
![Page 3](${page3})
Once the file is executed, it installs the malware on the recipient's system,
all under the guise of handling a legitimate business transaction.



# IOCs
| TYPE | IOC                                                                                     |
|------|-----------------------------------------------------------------------------------------|
| FILE | 53913469bad501d21ec745362fb23e9c8ba32d9eac0b99adcc561d96da563591                        |
| HOST | 80[.]190[.]85[.]84                                                                      |
| URL  | hxxps[://]www[.]mediafire[.]com/file_premium/z3bi56brp8muojj/Fatura[.]pdf_-_[.]jar/file |
| URL  | hxxps[://]app-kontor[.]online/                                                          |
| URL  | hxxps[://]inboxsender[.]gxsearch[.]club/PT2/serial[.]php                                |
| URL  | hxxps[://]roncluv[.]com/pt2/arquivos/bbnc[.]html                                        |
| URL  | hxxps[://]roncluv[.]com/pt2/arquivos/download[.]php                                     |

# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content, and don't worry we won't spam your feed ;)
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle7 = () => {
    useTitle('SucuriLabs | Threat Insights 0x24');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle7;
