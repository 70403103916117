import { InstagramLogo, LinkedinLogo, XLogo } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/oglogo.svg"
import FooterLinks from "./FooterLinks";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer>
            <div class="mx-auto text-gray-600 max-w-screen-xl space-y-8 px-4 py-4 pt-16 sm:px-6 lg:px-8">
                <div class="grid grid-cols-1 lg:gap-8 lg:grid-cols-3">
                    <div>
                        <Logo className="h-0 lg:h-6" />
                        <ul class="mt-8 flex gap-6">
                            <li>
                                <a
                                    href="https://www.instagram.com/sucurilabs/"
                                    rel="noreferrer"
                                    target="_blank"
                                    class="transition hover:opacity-75"
                                >
                                    <span class="sr-only">Instagram</span>
                                    <InstagramLogo size={24} />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://twitter.com/sucurilabspt"
                                    rel="noreferrer"
                                    target="_blank"
                                    class="transition hover:opacity-75"
                                >
                                    <span class="sr-only">X</span>
                                    <XLogo size={24} />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/sucurilabs/"
                                    rel="noreferrer"
                                    target="_blank"
                                    class="transition hover:opacity-75"
                                >
                                    <span class="sr-only">LinkedIn</span>
                                    <LinkedinLogo size={24} />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="grid grid-cols-1 gap-2 lg:gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-4">
                        <div></div>
                        <div></div>
                        <FooterLinks
                            title="Company"
                            links={[
                                { href: "/about/", label: t('About us') },
                                { href: "/blog/", label: t('Blog') }
                            ]}
                        />
                        <FooterLinks
                            title="Legal"
                            links={[
                                { href: "/privacy-policy/", label: t('Privacy Policy') },
                                { href: "/cookie-policy/", label: t('Cookie Policy') },
                                { href: "https://www.livroreclamacoes.pt/Inicio/", label: t('Complaint Book') },
                            ]}
                        />
                    </div>
                </div>
                <p class="text-xs">&copy; 2024. SucuriLabs, LDA. All rights reserved.</p>
            </div>
            <div id="toast" className="toast">
            </div>
        </footer >
    );
}

export default Footer;
