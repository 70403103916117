import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ti0x1e_card_img from '../assets/ti0x1e_card.png';
import ti0x1f_card_img from '../assets/ti0x1f_card.png';
import ti0x20_card_img from '../assets/ti0x20_card.png';
import ti0x21_card_img from '../assets/ti0x21_card.png';
import ti0x22_card_img from '../assets/ti0x22_card.png';
import ti0x24_card_img from '../assets/ti0x24_card.png';
import ti0x25_card_img from '../assets/ti0x25_card.png';
import ti0x26_card_img from '../assets/ti0x26_card.png';
import ti0x27_card_img from '../assets/ti0x27_card.png';
import ti0x28_card_img from '../assets/ti0x28_card.png';
import ti0x29_card_img from '../assets/ti0x29_card.png';
import ti0x2b_card_img from '../assets/ti0x2b_card.png';
import ti0x2c_card_img from '../assets/ti0x2c_card.png';
import ti0x2d_card_img from '../assets/ti0x2d_card.png';
import ti0x2e_card_img from '../assets/ti0x2e_card.png';
import { useTitle } from "../hooks/useTitle";

const Blog = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useTitle('SucuriLabs | Blog');
    return (
        <>
            <section class="mx-2 xl:mx-auto">
                <div class="mx-auto max-w-screen-xl lg:items-center space-y-8 pt-32 pb-4">
                    <div class="flex flex-col gap-3 lg:flex-row justify-between">
                        <div class="flex flex-col max-w-lg text-lg gap-2">
                            <h1
                                className="bg-clip-text text-4xl text-left font-bold"
                            >
                                {t('Blog')}
                            </h1>
                            <p className="prose max-w-lg">
                                {t('Read about the latest threat insights, customers stories, hot topics, and of course, we will talk about our Human Risk Management Platform.')}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="mx-2 xl:mx-auto">
                <div class="lg:mx-auto max-w-screen-xl lg:items-center space-y-8 lg:py-32 pb-16">
                    <div class="flex flex-col lg:flex-wrap lg:flex-row gap-3">
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x2e_card_img}
                                    alt="Threat Insights 0x2E" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x2E: Smuggling packages</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-45")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x2d_card_img}
                                    alt="Threat Insights 0x2D" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x2D: Low hanging fruit</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-44")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x2c_card_img}
                                    alt="Threat Insights 0x2C" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x2C: A smugglers tale</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-43")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x2b_card_img}
                                    alt="Threat Insights 0x2B" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x2B: Same old same old</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-42")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x29_card_img}
                                    alt="Threat Insights 0x29" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x29: Infostealers bonanza</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-41")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x28_card_img}
                                    alt="Threat Insights 0x28" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x28: Guloader Malspam</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-40")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x27_card_img}
                                    alt="Threat Insights 0x27" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x27: Lurking for creds</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-39")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x26_card_img}
                                    alt="Threat Insights 0x26" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x26: Harvesting season</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-38")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x25_card_img}
                                    alt="Threat Insights 0x25" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x25: AdwinRAT disguesed as invoice</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-37")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x24_card_img}
                                    alt="Threat Insights 0x24" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x24: Phishing for credentials</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-36")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x22_card_img}
                                    alt="Threat Insights 0x22" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x22: Agent Tesla</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-34")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x21_card_img}
                                    alt="Threat Insights 0x21" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x21: Formbook stealer</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-33")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x20_card_img}
                                    alt="Threat Insights 0x20" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x20: Infected</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-32")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x1f_card_img}
                                    alt="Threat Insights 0x1F" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x1F: Clever tactics</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-31")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-neutral/40 max-w-96 shadow-xl">
                            <figure>
                                <img
                                    src={ti0x1e_card_img}
                                    alt="Threat Insights 0x1E" />
                            </figure>
                            <div className="card-body">
                                <h2 className="card-title">Threat Insights 0x1E: Credential harvesting</h2>
                                <p className="prose">{t(`Sucurilabs Threat Insights provides a summary of what threat actors were delivering during the week to your inboxes.`)}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/blog/threat-insights-2024-30")}
                                    >
                                        {t('Read more')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Blog;
