import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import email1 from "../assets/blog/ti0x21/email_1.png"
import page1 from "../assets/blog/ti0x21/page_1.png"
import email2 from "../assets/blog/ti0x21/email_2.png"
import page2 from "../assets/blog/ti0x21/page_2.png"
import email3 from "../assets/blog/ti0x21/email_3.png"
import email4 from "../assets/blog/ti0x21/email_4.png"
import { useLayoutEffect } from "react";
import { useTitle } from "../hooks/useTitle";

const md = `
# Threat insights 0x21: Formbook stealer
## Unusual sign-in activity

![Email 1](${email1})

- **Rating:** ★★☆☆☆
- **Date:** 2024-08-06
- **Objective:** Credential harvesting
- **Analyst:** José Morim

This email is designed to impersonate the recipient's email service provider, attempting
to create a sense of urgency by notifying the user of unusual sign-in activity
on their account. The message is crafted to look legitimate, using the service
provider’s branding and language to convince the recipient that their account
security is at risk:
![Page 1](${page1})

The goal is to prompt the recipient to take immediate action, leading them to
click on the malicious links that points to a credential harvesting website.


## Fake request for quote
![Email 2](${email2})
- **Rating:** ★★★★☆
- **Date:** 2024-08-07
- **Objective:** Malware - Formbook
- **Analyst:** José Morim

The email pretends to be a request for a quote, asking the user to check the details.
However, its real goal is to trick the recipient into opening the attached file,
which contains a harmful executable. If the recipient runs the file, it infects
their system with the Formbook stealer, all while they believe they're handling
a normal business request:
![Page 2](${page2})
The threat actor's primary objective is to manipulate the victim into executing
the payload, thereby infecting their system with infostealer malware. This enables
the attacker to secretly harvest sensitive information and credentials, compromising
the victim's security.

## Annual compliance leave report
![Email 3](${email3})
- **Rating:** ★★☆☆☆
- **Date:** 2024-08-09
- **Objective:** Credential harvesting
- **Analyst:** José Morim

In this phishing email, the attacker impersonates the HR department of the
recipient's company. The message looks very genuine and the language use is
realistic, but the actual content is very suspicious. The link embedded in the email
leads to a credential harvesting website, which reveals this to be a malicious email.

## Bitcoin sextortion
![Email 4](${email4})
- **Rating:** ★☆☆☆☆
- **Date:** 2024-08-05
- **Objective:** Cryptocurrency extortion
- **Analyst:** José Morim

This is a compelling example of a typical phishing email leveraging sextortion
to extort money from the recipient. The attacker falsely claims to possess explicit
material involving the recipient and demands payment in Bitcoin, threatening to
release the alleged content if their demands are not met.

# IOCs
| TYPE | IOC                                                                                         |
|------|---------------------------------------------------------------------------------------------|
| URL  | hxxps[://]bafkreiceueo7767gj3gmx56zfp2ipy5vqrgogjv4n77fvrigtjlxtfhmjq[.]ipfs[.]dweb[.]link/ |
| FILE | be8d974d3e262ea959427e8964cbf4ec315d9ad11554e64b4aae8aa5ef34ff1b                            |
| FILE | de796036f54fe704ce2e91545fa22343fbca274f5838e056416d0cdeb51e4e3e                            |

# Keep up with threat insights

Threat insights is a weekly series were we present you with analysis from samples we collect. Follow us on social media for the latest feed and cybersecurity content. Stay informed and stay safe!

---

#### Get more insights like this
- Follow us on social media to get a weekly update of our latest content, and don't worry we won't spam your feed ;)
- [Join our private beta](https://sucurilabs.com/#join) and have a sneak peak of how your team will improve on their security posture.
`;

const BlogArticle4 = () => {
    useTitle('SucuriLabs | Threat Insights 0x21');
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <section class="bg-gray-950 text-gray-50">
                <div class="mx-2 lg:mx-auto max-w-screen-xl py-12">
                    <div class="lg:mx-auto max-w-screen-md text-left space-y-4 prose">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                table(props) {
                                    const { node, ...rest } = props;
                                    return <div className="overflow-x-auto">
                                        <table className="table" {...rest} />
                                    </div>;
                                },
                            }}
                        >
                            {md}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogArticle4;
